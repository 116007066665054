import React from 'react';
import { ContentContext } from 'utils/ContentContext';
import RichText from '../../atoms/RichText';
import { useContext } from 'react';

const Welcome = () => {
  return (
    <div className="manualBox">
      <div className="manualHeading">
        <h3>Helpcenter</h3>
      </div>
      {}
      <div className="manualText">
        Welcome to helpcenter, please choose a topic
      </div>
    </div>
  );
};

interface IContentAsRichTextProps {
  title: string;
  body: any;
}

const ContentAsRichText = ({ title, body }: IContentAsRichTextProps) => {
  return (
    <div className="manualBox">
      <div className="manualHeading">
        <h3>{title}</h3>
      </div>
      <div className="manualText">
        <RichText value={body} />
      </div>
    </div>
  );
};

export const Page = () => {
  const { currentPage, setCurrentPageById } = useContext(ContentContext);

  if (!currentPage.found) return <Welcome />;

  const ParentSectionWithSubSections = () => {
    return (
      <>
        <div className="manualBox">
          <div className="manualHeading">
            <h3>{currentPage.section.title}</h3>
          </div>
          <div className="manualText">
            <div className="chapterText">
              This chapter includes the following instructions:
            </div>

            <ul>
              {currentPage.section.subSections.map((sub, i) => {
                return (
                  <>
                    <li
                      className="chapters"
                      onClick={() => {
                        setCurrentPageById(sub.id);
                      }}
                    >
                      {sub.title}
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </>
    );
  };

  if (
    currentPage.found &&
    currentPage.section.subSections &&
    currentPage.section.subSections.length > 1
  ) {
    return <ParentSectionWithSubSections />;
  }

  return (
    <ContentAsRichText
      title={currentPage.section.title}
      body={currentPage.section.body}
    />
  );
};
