import React from 'react';
import { useContentContext } from 'utils/useContentContext';
import { Section } from '../../molecules/Section/Section';
import './style.css';

export const SideBar = () => {
  const { sectionTree } = useContentContext();

  return (
    <div className="sideBar" key={'sideBar'}>
      {sectionTree.map((section) => {
        return <Section key={section.id} section={section} />;
      })}
    </div>
  );
};
