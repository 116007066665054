import { ArrowDown } from 'components/atoms/ArrowDown';
import './style.css';

import { ArrowRight } from 'components/atoms/ArrowRight';
import { ISection } from 'utils/useSectionTree';
import { useContentContext } from 'utils/useContentContext';
interface ISubSectionProps {
  subContent: ISection;
}

export const SubSection = ({ subContent }: ISubSectionProps) => {
  const { currentPage, setCurrentPageById } = useContentContext();
  const isOpen = currentPage.found && currentPage.path.includes(subContent.id);

  return (
    <div className="subSection">
      <div
        className={
          currentPage.found && currentPage.path.includes(subContent.id)
            ? 'choosenSubSectionContent'
            : 'subSectionContent'
        }
        key={subContent.title}
        onClick={() => {
          setCurrentPageById(subContent.id);
        }}
      >
        <p className="subSectionTitle">{subContent.title}</p>
        {subContent.subSections.length > 0 ? (
          <div
            className="arrowIcon"
            onClick={(e) => {
              setCurrentPageById(subContent.id);
              e.stopPropagation();
            }}
          >
            {isOpen ? <ArrowDown /> : <ArrowRight />}
          </div>
        ) : null}
      </div>

      {isOpen && (
        <>
          {subContent.subSections.map((subSection) => {
            return <SubSection key={subSection.id} subContent={subSection} />;
          })}
        </>
      )}
    </div>
  );
};
