import * as React from 'react';

export const Any4mLogo = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 617.3 159.6"
    style={{
      enableBackground: 'new 0 0 617.3 159.6',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{'.st0{fill:#1973b8}.st1{fill:#737474}'}</style>
    <path
      className="st0"
      d="M137.3 62.6c1.1-1.4 2.7-1.5 4-2.2 12.1-6.1 19.3-19.3 17.6-32.5-1.8-13.6-12-24.5-25.6-27.3C120.4-2 106.2 4.2 100 16.3c-2.5 4.9-5.2 6.7-10.2 5.8-.6-.1-1.3-.1-2 0-3 .5-4.5-.8-5.9-3.7C76.1 6.8 66.6.4 53.3.2 47 .1 40.6 0 34.3.2 20.9.7 11.2 7.3 6 19.6.7 31.9 3.1 43.4 11.9 53.4 17.7 59.9 25.3 63 34 63.6c.7 4.2.1 8.2.4 12.1.2 3.1-.8 5.3-3 7.5-9.8 9.5-19.3 19.3-29 29-1 1-2.5 1.7-2.5 3.7l44.4 43.8c1.4-.6 2.3-2 3.5-3.1 9.6-9.5 19.1-19.1 28.6-28.6 1.2-1.2 2.2-3 4.4-2.9 4.8.1 9.6-.3 14.9.3v21.4h63V83.3h-21.6V62.6zm-10-43.2c6.6-.1 12.4 5.7 12.5 12.3 0 6.7-5.6 12.4-12.3 12.4-6.8 0-12.3-5.4-12.3-12.2-.1-6.8 5.4-12.4 12.1-12.5zM22.9 32.5c-.3-6.8 4.2-12.5 11.1-13 6.8-.5 13.7-.5 20.5 0 6.8.5 11.4 6.4 11 13.1-.3 6.5-5.3 11.3-12.1 11.5-3.2.1-6.3 0-9.5 0-2.8 0-5.7.1-8.5 0-7.1-.1-12.2-4.9-12.5-11.6zm21.4 98.9c-5.3-5.2-10.9-10.8-16.7-16.5 5.3-5.2 10.9-10.7 16-15.6 5.3 5.3 11.1 10.9 16.6 16.4-5 4.9-10.6 10.5-15.9 15.7zM96 105.2c-5.5.6-10.4.2-15.3.3-1.7 0-2.6-1.2-3.7-2.3-7-7-13.9-13.9-20.8-20.9-.9-.9-2.2-1.7-2.2-3.2 0-5.1-.4-10.2.3-15.5 4.9-.3 9.4-1.4 13.5-3.6 6.5-3.5 11.4-8.5 14.4-15.3 1-2.2 2-3.3 4.7-3.4 6.2-.3 11.2-.2 14 7.3 2.2 5.9 7.8 9.8 13.9 12.2 2.5 1 3.3 2.2 3.2 4.8-.1 5.8 0 11.5 0 17.8H96v21.8zm43.8-1.8v21.8c0 1.9-.9 2.6-2.8 2.6h-21.7v-24.4h24.5zM576.5 116h-21.2c-1.3-1.4-.7-3.2-.7-4.8-.1-12.5 0-25 0-37.4 0-7.4-3.1-11.4-8.8-11.5-5.6-.1-9.1 4.1-9.1 11.3-.1 12.1 0 24.3 0 36.4v5.8h-21.9V43.2h20.9c1.2 1.3.2 2.9 1 4.6 11.7-7.8 23.3-8.4 34.7 1.7 5.8-5.1 12.6-7.6 20.4-7.5 14.5.2 24.9 9.4 25.4 23.9.5 16.3.1 32.6.1 48.9 0 .3-.3.6-.5 1.1h-21c-1-1.8-.6-3.5-.6-5.1V74.4c0-2 0-4-.7-5.9-1.4-4-5.1-6.6-9.1-6.3-4.2.4-7.4 3.4-8.1 7.6-.2 1.5-.2 3-.2 4.5v36.4c-.1 1.8.3 3.5-.6 5.3z"
    />
    <path
      className="st1"
      d="M299.2 90.4c-8-22.2-16-44.3-24.1-66.4-1.7-4.8-1.8-4.8-7.1-4.8-2.7 0-5.3.2-8-.1-3.7-.4-5.6.8-6.9 4.5-5.6 16.1-11.5 32.2-17.4 48.2-5.2 14.5-10.5 29-16.1 44.4h22.7c1.9 0 2.6-1 3-2.6.9-3 2-6 2.9-9 .6-2.1 1.8-2.8 4-2.8 8 .1 16 .1 24 0 2.2 0 3.3.8 3.8 2.9.8 3 1.9 6 2.8 9 .4 1.4 1 2.4 2.6 2.4h23c-3.1-8.9-6.2-17.3-9.2-25.7zm-43.1-8.5c2.8-8.4 5.5-16.4 8.4-25.3 3.4 8.7 5.5 16.8 8.2 25.3h-16.6zM373.5 116.1h-20.8c-1.4-1.5-.8-3.4-.8-5.2-.1-12 0-23.9 0-35.9 0-1.8 0-3.6-.4-5.4-1.2-4.6-5.1-7.6-9.4-7.1-4.6.5-8 3.8-8 8.7-.2 12.6-.1 25.3-.1 37.9 0 2.3.3 4.6-.4 7.1h-21.8V43.6h21c1 1.3.1 2.9.8 4.3 1.2.4 1.8-.6 2.5-1.1 16.3-10.9 36.7-1.6 37.8 18 .9 16.7.2 33.6.2 50.4-.1 0-.3.3-.6.9zM386.5 140.6v-19.1c.6-.2 1.1-.5 1.5-.5 8.6-.4 9.4-1.1 11.8-9.1.7-2.3.2-4.2-.6-6.3l-21-57c-.6-1.7-1.6-3.3-1.5-5.5 6.9 0 13.7.1 20.5-.1 2.6-.1 2.9 1.7 3.4 3.4 3.6 11 7.2 22.1 10.9 33.3 2-1.4 1.9-3.6 2.4-5.3 3-9.3 6-18.6 8.7-28 .8-2.6 2-3.4 4.5-3.4 6.5.1 12.9 0 19.9 0-3.8 10.5-7.5 20.5-11.1 30.4-6.2 16.8-12.5 33.6-18.8 50.4-5 13.3-10.5 17.2-24.6 17.2-1.7 0-3.7.3-6-.4z"
    />
    <path
      className="st0"
      d="M484.6 43c-6.5 17.6-12.7 34.6-19.1 51.8h17.6V72.7h21v53.9h-20.4c-1-3.2.1-6.6-.9-10.3h-41.6c.4-10.5-1.1-20.5 3.1-30.3 5.5-12.8 10-26 14.6-39.2 1.1-3.1 2.6-4 5.7-3.9 6.4.3 12.7.1 20 .1z"
    />
  </svg>
);
