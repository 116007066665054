import { useContext } from 'react';
import Async from 'react-select/async';
import { ContentContext } from 'utils/ContentContext';
import { useContentfulClient } from 'utils/useContentfulClient';

export const Search = () => {
  const { setCurrentPageById, allIds } = useContext(ContentContext);
  const contentfulClient = useContentfulClient();

  const loadOptions = async (inputValue: string) => {
    if (!inputValue) return [];

    const data = await contentfulClient.getEntries<{ title: string }>({
      content_type: 'helpSection',
      query: inputValue,
    });

    console.log(data);

    return data.items
      .filter((x) => allIds.includes(x.sys.id))
      .map((x) => ({ label: x.fields.title, value: x.sys.id }));
  };

  return (
    <Async
      className="searchBar"
      isSearchable={true}
      loadOptions={loadOptions}
      isClearable={true}
      placeholder={<div className="select-placeholder-text">Search</div>}
      components={{
        NoOptionsMessage: () => <div></div>,
      }}
      onChange={(choice: any, x) => {
        if (choice) setCurrentPageById(choice?.value);
      }}
    />
  );
};
