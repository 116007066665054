import { createContext } from 'react';
import { FoundPageWithPath } from './useContent';
import { ISection } from './useSectionTree';

export interface IContentContextProps {
  currentPage: FoundPageWithPath;
  sectionTree: ISection[];
  setCurrentPageById: (id: string) => void;
  allIds: string[];
}

export const ContentContext = createContext<IContentContextProps>({
  currentPage: { found: false },
  sectionTree: [],
  allIds: [],
  setCurrentPageById: (id: string) => {
    debugger;
  },
});
