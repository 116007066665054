import { useState, useMemo } from 'react';
import { IContentContextProps } from './ContentContext';
import { ISection, useSectionTree } from './useSectionTree';

export type FoundPageWithPath =
  | { found: false }
  | { found: true; path: string[]; section: ISection };

const findPageByIdWithPath = (
  data: ISection | ISection[],
  id: string
): FoundPageWithPath => {
  const internalFind = (
    currentSection: ISection,
    id: string,
    idPath: string[] = []
  ): FoundPageWithPath => {
    if (currentSection.id === id) {
      return {
        found: true,
        path: [...idPath, currentSection.id],
        section: currentSection,
      };
    }

    if (!currentSection.subSections || currentSection.subSections.length == 0)
      return { found: false };

    const currentPath = [...idPath, currentSection.id];
    for (let i = 0; i < currentSection.subSections.length; i++) {
      const section = currentSection.subSections[i];

      const result = internalFind(section, id, currentPath);
      if (result.found) return result;
    }

    return { found: false };
  };

  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const section = data[i];
      const result = internalFind(section, id);
      if (result.found) return result;
    }

    return { found: false };
  }

  return internalFind(data, id);
};

export const useContent = () => {
  const { tree: sectionTree, allIds } = useSectionTree();
  const [currentPageId, setCurrentPageId] = useState<string | null>(null);

  const setCurrentPageById = (id: string) => {
    setCurrentPageId(id);
    window.scrollTo({
      top: 0,
    });
  };

  const currentPage: FoundPageWithPath = useMemo(() => {
    if (!currentPageId) return { found: false };

    return findPageByIdWithPath(sectionTree, currentPageId);
  }, [currentPageId]);

  return {
    currentPage,
    sectionTree,
    setCurrentPageById,
    allIds,
  } as IContentContextProps;
};
