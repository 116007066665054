import { Entry } from 'contentful';
import { useEffect, useState } from 'react';
import { useContentfulClient } from './useContentfulClient';

export interface ISection {
  id: string;
  title: string;
  name: string;
  body: any;
  content?: any;
  subSections: ISection[];
}

const getSubsections = (
  sections: Entry<IContenfultHelpSectionItem>[]
): ISection[] => {
  return sections
    .map((data) => {
      if (!data.fields) return null;
      return {
        id: data.sys.id,
        title: data.fields.title,
        body: data.fields.body,
        name: data.fields.name,
        content: data.fields.content,
        subSections: data.fields.sections
          ? getSubsections(data.fields.sections)
          : [],
      };
    })
    .filter((x) => !!x) as ISection[];
};

interface IContenfultHelpSectionItem {
  title: string;
  name: string;
  body: any;
  content: any;
  sections: Entry<IContenfultHelpSectionItem>[];
}

const transformToSectionsFromContentfulItems = (
  items: Entry<IContenfultHelpSectionItem>[]
): ISection[] => {
  return items.flatMap((item) => {
    if (item.fields.title !== 'Contents' || !item.fields.sections)
      return [] as ISection[];

    return item.fields.sections
      .map((section) => {
        if (!section || !section.fields) return null;

        const newSection: ISection = {
          id: section.sys.id,
          title: section.fields.title,
          name: section.fields.name,
          body: section.fields.body,
          subSections: [],
        };

        if (section.fields.sections) {
          newSection.subSections = getSubsections(section.fields.sections);
        }

        return newSection;
      })
      .filter((x) => !!x) as ISection[];
  });
};

const flattenSections = (sections: ISection[]): ISection[] =>
  sections.flatMap((x) => [x, ...flattenSections(x.subSections ?? [])]);

export const useSectionTree = () => {
  const contentfulClient = useContentfulClient();

  const [tree, setTree] = useState<ISection[]>([]);
  const [allIds, setAllIds] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      const result =
        await contentfulClient.getEntries<IContenfultHelpSectionItem>({
          content_type: 'helpSection',
        });

      const transformedTree = transformToSectionsFromContentfulItems(
        result.items
      );
      const foundIds = flattenSections(transformedTree).map((x) => x.id);
      setTree(transformedTree);
      setAllIds(foundIds);
    })();
  }, []);

  return { tree, allIds };
};
