import React from 'react';
import './App.css';
import { ManualApp } from './ManualApp';

import { useState, createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { useContent } from 'utils/useContent';
import { ContentContext } from 'utils/ContentContext';
import { ContentfulClient } from 'react-contentful/types';

function App() {
  const content = useContent();
  return (
    <div className="App">
      <ContentContext.Provider value={{ ...content }}>
        <ManualApp />
      </ContentContext.Provider>
    </div>
  );
}

export default App;
