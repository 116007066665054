import React from 'react';

export const ArrowRight = () => {
  return (
    <svg
      className="arrows"
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-12 -12 24 24"
    >
      <polyline className="cls-1" points="0,-10 10,0 0,10" />
    </svg>
  );
};
