import './style.css';

import { ArrowDown } from 'components/atoms/ArrowDown';
import { ArrowRight } from 'components/atoms/ArrowRight';
import { SubSection } from '../Subsection/Subsection';
import { useContentContext } from 'utils/useContentContext';
import { ISection } from 'utils/useSectionTree';

interface ISectionProps {
  section: ISection;
}

export const Section = ({ section }: ISectionProps) => {
  const { currentPage, setCurrentPageById } = useContentContext();

  const isOpen = currentPage.found && currentPage.path.includes(section.id);

  return (
    <>
      <div
        key={section.title}
        className={isOpen ? 'choosenSection' : 'section'}
        onClick={() => {
          setCurrentPageById(section.id);
        }}
      >
        <p className="contentTitle">{section.title}</p>
        {section.subSections.length > 0 ? (
          <div
            className="arrowIcon"
            onClick={(e) => {
              setCurrentPageById(section.id);
              e.stopPropagation();
            }}
          >
            {isOpen ? <ArrowDown /> : <ArrowRight />}
          </div>
        ) : null}
      </div>

      {isOpen && (
        <>
          {section.subSections.map((subContent) => (
            <SubSection key={subContent.id} subContent={subContent} />
          ))}
        </>
      )}
    </>
  );
};
