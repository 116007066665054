import React, { useEffect, useState } from 'react';
import { SideBar } from './components/organisms/SideBar/Sidebar';
import { Page } from './components/organisms/Page/Page';
import './ManualApp.css';
import { useContext } from 'react';
import { ContentContext } from 'utils/ContentContext';
import { Any4mLogo } from 'components/atoms/Any4mLogo';
import { Search } from 'components/organisms/Search/Search';

export const ManualApp = () => {
  const {} = useContext(ContentContext);

  return (
    <>
      <div className="topBar">
        <div className="logo">
          <Any4mLogo />
        </div>

        <div>
          <h1 className="topBarTitle">Process Modeller Help center</h1>
        </div>
        <div className="searchLanguage">
          <div>
            <Search />
          </div>
          <div className="language"></div>
        </div>
      </div>

      <div className="page">
        <SideBar />

        <Page />
      </div>
    </>
  );
};
